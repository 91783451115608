
/* @import url('https://fonts.googleapis.com/css2?family=Schibsted+Grotesk&display=swap'); */

/* UBUNTU */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}





body{
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.modal-open{
  overflow: hidden;
}
::-webkit-scrollbar{
	width: 7px;
}

::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background: linear-gradient(transparent, #04BF9D);
}

::-webkit-scrollbar-thumb:hover{
	background: linear-gradient(transparent, #5d9be2 );
}

.mainInicio{
  background: #D5E5F2;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

}

/* Informacion general del aplicativo */
.titleContainer{
  width: 100%;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}


.titleInit {
	text-align: center;
}

#spanInit{
  background: linear-gradient(to right, #04BF9D, #63d2de, #04BF9D);
  -moz-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0);
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0);
}

.subtitle{
  margin-top: 0.5rem ;
  font-size: 1.5rem;
  padding: 1rem;
}

.contLogo{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoInitial{
  width: 70%;
  height: 100%;
  /* align-self: center; */
}

/* ------------------ */

/* Informacion Servicios */
.service{
  margin-top: 3rem;
  font-size: 3.5rem;
  color: #73563D;
}

.serviceTitle{
    font-size: 2.3rem;
    padding: 1rem;
    color: #73563D;
}

.services1{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.card{
  width: 40%;
  height: auto;
  background-color: #fafafa;
  margin: 2rem;
  border-radius: 5px;
  box-shadow: 2px 4px 10px 2px rgb(166, 166, 166);
  padding: 0.5rem;
  text-align: center;
  transition: .4s;
}

.card:hover{
  transform:scale(1.03);
  transition: .4s;
}

.card h3{
  font-size: 2.3rem;
  background: -webkit-linear-gradient(#000000, #04D9D9);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  word-break: keep-all;
}

.service-img{
  width: 50%;
  height: 40%;
  border-radius: 10px;
  margin-top: 10px;
}

.point{
  text-align: left;
  font-size: 1.3rem;
  margin: 0.5rem;
}


/* Servicios spaceUser */
.contInfo{
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2rem;
  
}


.contText{
  width: 60%;
  height: auto;
  font-size: 1.2rem;
  padding: 15px;
}
.contText > p {
	font-size: 1.5rem	;
	margin: 20px 0 30px;
}

.imgInfo{
  width: 40%;
  margin: 1rem;
}

.imgInfoP{
  width: 30%;
  height: 20rem;
}

hr{
  width: 100%;
  height: 3px;
  color: #73563D;
}

/* Login-Register */

.mainLogin{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.logoLogin{
  width: 40%;
  display: flex;
  align-self: center;
  margin-left: 2.4rem;
}


.titleLogin{
  font-size: 3rem;
	font-weight: bold;
	margin: 0;
  color: #04BF9D;
}

.titleLog{
  font-size: 3rem;
  color: #000000;
	font-weight: bold;
}


#textlogin{
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
}


.contTitle{
  display: none;
  width: 100%;
  position: absolute;
  top: 0%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  z-index: 15;
  transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.contTitle-active{
  display: flex;
}

.chooseRol{
  font-size: 3rem;
  animation: .2s;
  transform: translateX(0);
	transition: transform 0.6s ease-in-out;
  color: #73563D;

}

.contLogin{
width: 100%;
display: none;
position: absolute;
align-items: center;
text-align: center;
justify-content: center;
top: 80%;
font-size: 2rem;
z-index: 20;	
transform: translateX(0);
transition: transform 0.6s ease-in-out;
}

#itsLogin{
  color: #73563D;
  cursor: pointer;
  transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.textAccount{
  position: relative;
  z-index: 200;
  font-size: 1.5rem;
  margin-top: 1rem;
}
.contLogin-active{
  display: block;
}

.choseeAccount{
  font-size: 1.5rem;
  margin-top: 0.7rem;
}

.imgCreate{
  width: 80%;
  height: 50%;
  cursor: pointer;
  transition: .4s;
}
.imgCreate:hover{
  transform:scale(1.05);
  transition: .4s;
}
.spanLogin {
	font-size: 12px;
}

a {
	color: rgb(255, 255, 255);
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

button {
	border-radius: 20px;
	border: 1px solid #ffffff;
	background: linear-gradient(to right, #000000, #04BF9D);
	color: white;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	margin-top: 20px;
  transition: .2s;
}

.spanCreate{
  color: white;
  font-size: 12px;
}

.titleUp{
  color: #000000;
  font-size: 3rem;
}
button:hover{
	background: linear-gradient(to right, #04BF9D, #f6f6f6);
	color: #000000;
  cursor: pointer;
}

button > a{
  color: black;
}
button:active {
	transform: scale(0.8);
}

button:focus {
	outline: none;
}

button.ghostLogin{
  display: none;
  flex-direction: row;
  justify-content: space-evenly;
  width: 12rem;
  background: linear-gradient(to right, #04BF9D, #f6f6f6);
	border-radius: 20px;
	border: 1px solid #ffffff;
	color: #000000;
  align-self: center;
  padding: 12px;
  align-items: center;
  text-align: center;
}

button.ghostLogin:hover{
  background: linear-gradient(to right, #000000, #04BF9D);
	color: white;
	border: 1px solid white;
}



button.ghost {
	background: linear-gradient(to right, #04BF9D, #f6f6f6);
	border-radius: 20px;
	border: 1px solid #000000;
	color: #000000;
}

button.ghost:hover{
	background: linear-gradient(to right, #000000, #04BF9D);
	color: white;
	border: 1px solid white;
}

#login{
	background-color: #ffffff;
	display: flex;
	align-items: center;
  position: relative;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	/* height: 100%; */
	text-align: center;
  z-index: 1;
}

.chose{
  display: none;
}
.formRegister{
  background-color: #ffffff;
	display: flex;
	align-items: center;
  position: relative;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
  z-index: 1;
}

.inputLogin{
  background-color: #eee;
	border: none;
	outline: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
	font-size: 1.2rem;
	font-family: Arial, Helvetica, sans-serif;
}
.inputLogin:focus{
	border-color: #04BF9D;
	box-shadow:0 0 8px 0 #04BF9D;
}


.forgotPassword{
  margin-top: 5%;
  color: black;
  transition: .2s;
  font-size: 1rem;
  text-align: center;

}

.forgotPassword:hover{
  color: #04BF9D;
  transition: .2s;
}

.container {
	background-color: #fff;
	border-radius: 10px;
	box-shadow:0 0 15px 0 rgb(0, 0, 0);
	position: relative;
	overflow: hidden;
	width: 75%;
  height: 75vh;
	max-width: 100%;
}

.form-container {
	position: absolute;   
   /* HACE QUE SE VEA DETRAS O DELANTE */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
  z-index: 1;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
  overflow: auto;
}

.container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	/* z-index: 10; */
}

.container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: linear-gradient(to right, #dfdfdf, #04BF9D);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #000000;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}


/* crear cuenta */

.contAccount{

  width: 20%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.AccountImg{
width: 10%;
height: 70%;
}

/* Modales de Creacion de cuenta */

.contModal{
  position: absolute;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
}

.modalUser{
  width: 75%;
  height: 78%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  flex-direction: column;
  z-index: 2000;
  color: rgb(0, 0, 0);
  border: solid 1px #000000;
	background: linear-gradient(to right, #dfdfdf, #04BF9D);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: showModalUser 0.5s; 
}

/* Este es el overlay de las modales hechas por react, es un contenedor, aqui lo usamos
 para especificar su posicionamiento sobre otros elementos */
.ReactModal__Overlay {
z-index: 1000;
}


@keyframes showModalUser {
	0%{
    transform: translate(150%, -50%);
	}
	100%{ 
    transform: translate(-50%, -50%);
	}
}




/* ---------------------------- */

.modalParking{
  width: 75%;
  height: 85%;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  position: fixed;
  flex-direction: column;
  z-index: 200; 
  border: solid 1px #000000;
  background: linear-gradient(to right, #dfdfdf, #04BF9D);
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: showModalParking 0.5s; 
  overflow-y: scroll;
}

.ReactModalPortal{
  overflow-y: scroll;
}
.Bg-Map{
  position: relative;
  top: 50%;
  right: 40%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  align-self: center !important;
}

.messageMap{
  width: 100%;
  text-align: center;
  color: #04BF9D;
  font-size: 2rem;
  /* margin-top: 1rem; */
}

@keyframes showModalParking {
  0% {
    transform: translate(-150%, -50%);
  }
  100% { 
    transform: translate(-50%, -50%);
  }
}


/* formularios de creacion de cuentas */

.createAccount{
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  border-radius: 0 0 2% 2%;
}

.createAccountUser{
  width: 100%;
  height: 90%;
  background-color: #ffffff;
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  overflow: hidden;
  position: relative;
  border-radius: 0 0 2% 2%;
}

#titleCreate{
  font-size: 2.5rem;
  color: #000000;
  margin: 1rem;
}

.dataUser{
  height: 100%;
  width: 50%;
  padding-left: 15px;
  overflow-y: scroll;
  overflow-x: hidden;

}


.dataUserSpace{
  /* height: 100%; */
  width: 50%;
  padding-left: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.dataUserSpace > h3{
  width: 100%;
  text-align: center;
  color: #04BF9D;
  font-size: 2rem;
  margin-top: 1rem;
}

.dataUser >h3{
  width: 100%;
  text-align: center;
  color: #04BF9D;
  font-size: 2rem;
  margin-top: 1rem;
}




.dataVehicle{
  height: 100%;
  width: 50%;
}

.dataVehicle > h3{
  width: 100%;
  text-align: center;
  color: #04BF9D;
  font-size: 2rem;
  margin-top: 1rem;

}


.dataParking{
  height: 100%;
  width: 50%;
}
.dataParking > h3{
  width: 100%;
  text-align: center;
  color: #04BF9D;
  font-size: 2rem;
  margin-top: 1rem;
}

/*  */
.contGroup{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.form__group {
  position: relative;
  padding: 20px 20px 0;
  width: 50%;
  max-width: 90%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 17px;
  color: #000000;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 17px;
  cursor: text;
  top: 70%;
}

.form__label {
  position: absolute;
  top: 30%;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #7b7b7b;
  pointer-events: none;
}

.form__field:focus {
  border-width: 2px;
  border-image: linear-gradient(to right, #04D9D9, #04BF9D);
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 30%;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #04BF9D;
  font-weight: 700;
}


.form__field:required, .form__field:invalid {
  box-shadow: none;
} 

#select-platform{
  width: 17%;
  height: 2rem;
  font-size: 17px;
  align-self: center;
  position: absolute;
  border: solid 1px #04BF9D;
  border-radius: 10px;
  color: #7b7b7b;
  right: 5%;
  bottom: 54%;
}

.contAll{
  display: flex;
}

.contAllUser{
  display: flex;
}


/* BUTTON CREAR CUENTA  */

#btnCreateUser{
  position: absolute;
  top: 80%;
  right: 40%;
  color: #000000;
  background: #d5e5f2;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid #04BF9D;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 #04BF9D;
}

#btnCreateUser:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #04BF9D;
}

#btnCreateUser:active {
  transform: scale(0.8);
}


#btnCreateUserPark{
  /* position: a; */
  /* top: 100%; */
  align-self: center;
  /* right: 43%; */
  color: #000000;
  background: #d5e5f2;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid #04BF9D;
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: inset 0 0 0 0 #04BF9D;
}

#btnCreateUserPark:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #04BF9D;
}

#btnCreateUserPark:active {
  transform: scale(0.9);
}



/* Tamaño de iconos sweetalert */
.swal2-popup {
  font-size: 16px;
}

.swal2-icon {
  font-size: 1rem;
  overflow-y: hidden;
}

.swal2-content {
  margin-top: 0.5em;
  margin-bottom: 1em;
  line-height: 1.2;
}


.leaflet-container{
  height: 100vh;
}

/* Alert log in */
.swal2-popup {
  overflow: hidden !important;
}
.swal2-loading {
  overflow: hidden !important;
}

/* Media queries */


@media screen and (max-width: 600px) {

  .titleContainer{
    max-width: 600px;
    height:100vh;
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    flex-direction: column-reverse;
  }

  .titleInit {
    width: 60%;
    font-size: 2.3rem;
    text-align: center;
  }

  .logoInitial{
    width: 90%;
    height: 8rem;
  }

  #eslogan{
    font-size: 1.5rem;
    text-align: center;
  }



  /* MainLogin */

  .container {
    width: 90%;
  }

  #login{
    padding: 0;
  }

  .titleLogin{
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .inputLogin{
    width: 9rem;
    font-size: 1rem;
  }
  #btnLogin{
    width: 9rem;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
    padding: 10px;
  }

  button.ghostLogin{
    display: flex;
    width: 10rem;
    font-size: 1rem;
    padding: 10px;
  }

  .forgotPassword{
    width: 90%;
    align-self: center;
  }
  
  .titleLog{
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  #textlogin{
    font-size: 1rem;
    padding: 0;
  }

  #signUp{
    display: none;
  }

  .sign-up-container{
    width: 50%;
  }

  .sign-in-container{
    width: 100%;
  }

  .overlay-right{
    display: none;
  }

  #textlogin{
    display: none;
  }



  /* Informacion */
  .service{
    font-size: 2.2rem;
    text-align: center;
  }
  .services1{
    flex-direction: column;
  }
  .card{
    width: 80%;
  }
  .card h3{
    font-size: 1.8rem;
  }

  .contText{
    padding: 0;
  }

  .contText > p{
    font-size: 1rem;
    margin: 0;
  }

  .serviceTitle{
    font-size: 1.5rem;
  }


  .imgInfo{
    width: 50%;
    height: 11rem;
    margin: 0;
  }

  .chose{
    display: flex;
    font-size: 1.3rem;
  }
  button{
    padding: 0%;
    font-size: 1rem;
  }
  .imgCreate{
    width: 150%;
    height: 30%;
    cursor: pointer;
    transition: .4s;
  }
  .contTitle{
    top: 5%;
  }
  .textAccount{
    font-size: 1.2rem;
  }
  .choseeAccount{
    font-size: 1.3rem;
  }


  /* queries de las modales de creacion de cuenta */

  .modalParking{
    width: 85%;
    flex-direction: column;
    text-align: center;
  }
  #titleCreate{
    width: 100%;
    /* align-self: center; */
    font-size: 1.5rem;
  }
  .createAccount{
    flex-direction: column;
  }
  .dataUser{
    width: 100%;
    height: 50%;
  }
  .dataUser >h3{
  font-size: 1.4rem;
  }

  .dataParking{
    width: 100%;
  }
  .dataParking > h3{
    font-size: 1.4rem;
  }
  .contGroup{
    width: 100%;
    height: auto;
  }

  .form__group {
    width: 50%;
    padding: 10px 10px 0;
  }
  .form__field{
    align-self: center;
  }

  .form__field:placeholder-shown ~ .form__label{
    top: 70%;
    font-size: 14px;
  }
  .form__label{
    top: 30%;
    font-size: 14px;
  }

  .form__field:focus ~ .form__label {
    font-size: 14px;
    top: 30%;
  }
  
  .dataParking{
    display: flex;
    flex-direction: column;
    height: 50%;
  }
  #btnCreateUserPark{
    position: fixed;
    top: 100%;
    right: 25%;
    margin: 1rem;

  }
  
}