@import url('https://fonts.googleapis.com/css2?family=Schibsted+Grotesk&display=swap');



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body{
    width: 100%;
    height: 100%;
    font-family: 'Schibsted Grotesk', sans-serif;
}




#mainRecover{
  background: #D5E5F2;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: aqua;
}


.containerLogo{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.logoRecover{
  width: 55%;
  height: 85%;
  margin-left: 5rem;
}


.contPassword{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ImgRecover{
    width: 40%;
    height: 90%;
}

#formRecover{
    width: 45%;
    height: 90%;
    background-color: white;
    margin: 1rem;
    padding: 30px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2%;
}

#formRecover > h2{
    font-size: 3.5rem;
}

#formRecover >p{
    color: #000000;
}

#formRecover > .contGroup{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
#formRecover > .contGroup > .form__group {
  position: relative;
  padding: 20px 20px 0;
  width: 70%;
  max-width: 90%;
}
#formRecover > .contGroup >  .form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 20px;
  color: #000000;
  padding: 9px 0;
  background: transparent;
  transition: border-color 0.2s;
}
  
  .form__field::placeholder {
    color: transparent;
  }
  
  #formRecover > .contGroup > .form__field:placeholder-shown ~ .form__label {
    font-size: 20px;
    cursor: text;
    top: 70%;
  }
  
  #formRecover > .contGroup > .form__label {
    position: absolute;
    top: 30%;
    display: block;
    transition: 0.2s;
    font-size: 20px;
    color: #7b7b7b;
    pointer-events: none;
  }
  
  .form__field:focus {
    border-width: 2px;
    border-image: linear-gradient(to right, #04D9D9, #04BF9D);
    border-image-slice: 1;
  }
  
  #formRecover > .contGroup > .form__field:focus ~ #formRecover > .contGroup >.form__label {
    position: absolute;
    top: 30%;
    display: block;
    transition: 0.2s;
    font-size: 20px;
    color: #04BF9D;
    font-weight: 700;
  }
  
  
  #formRecover > .contGroup > .form__field:required, #formRecover > .contGroup >.form__field:invalid {
    box-shadow: none;
  } 



  @media screen and (max-width: 600px) {
  
    #formRecover {
      width: 85%;
      height: 70%;
      background-color: white;
      margin: 1rem;
      padding: 30px;
      box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
      border-radius: 2%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

    #formRecover > h2{
      width: 100%;
      height: 30%;
      font-size: 2.3rem;
      margin-bottom: 3rem;
      text-align: center;
      overflow: hidden;
  }
    #formRecover >p {
      height: 30%;
      color: #000000;
      /* text-transform: capitalize; */
      font-size: 1.2rem;
      text-align: justify;
      margin-bottom: 1rem;
      /* overflow: hidden; */
      
  }
    .containerLogo{
      overflow: hidden;
  }
    .ImgRecover{
      display: none;
  }
    #formRecover > .contGroup {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      overflow: hidden;
  }
    #formRecover > .contGroup > .form__group {
      position: relative;
      padding: 20px 20px 0;
      width: 100%;
      max-width: 90%;
      margin-bottom: 3rem;
      align-self: center;
      margin-left: 1rem;
  }
    .form__field {
      font-family: inherit;
      width: 100%;
      border: none;
      border-bottom: 2px solid #9b9b9b;
      outline: 0;
      font-size: 20px;
      color: #000000;
      padding: 7px 0;
      background: transparent;
      transition: border-color 0.2s;
  }
    .contPassword{
      width: 100%;
      height: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
  }
    button{
      overflow: hidden;
      width: 10rem;
      height: 3rem;
      font-size: 1rem;
    }
  }
