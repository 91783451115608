*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

body{
    width: 100vw;
    background-color: #D5E5F2 ;
}

#HomeUser{
    width: 100%;
    height: auto;
    /* background: white; */
    background: #D5E5F2;
    display: flex;
    flex-direction: column;
}

.headerUser{
    width: 100%;
    height: 15vh;
    background: white;
    background: linear-gradient(to right, #dfdfdf, #04BF9D,  #dfdfdf);
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: hidden;
}

.headerUser > h3{
    font-size: 2rem;
}
.contIcon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 2px black;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3rem;
    cursor: pointer;

}
.icon-user{
    margin-top: 10px;
    font-size: 1.8rem;
    color: white;
}

#inputSearch{
    width: 30%;
    height: 2rem;
    border-radius: 10px;
    padding: 5px;
    font-size: 1.2rem;
}

#logoHomeUser{
    width: 200px;
    height: 100px;
    margin-left: 1rem;
}


#mainHomeUser{
    width: 100%;
    /* height: auto; */
    /* height: 80vh; */
    overflow-x: hidden;
    flex-wrap: wrap;

}

.containerParkings{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.cardParking{
    width: 22%;
    height: 25rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    border-radius: 10px;
    padding: 10px;
    animation: .2s;
    transition: all 1s;
    box-shadow: 2px 2px 4px 4px rgb(168, 168, 168);
    margin-bottom: 2rem;
    cursor: pointer;
}

.cardParking:hover{
    transform: scale(1.05);
}


.ImgParking{
    width: 100%;
    height: 16rem;
    border-radius: 10px;
}
.icon-bell{
    font-size: 2.2rem;
}


.nameParking{
    font-size: 1.5rem;
    margin-top: 0.7rem;
}

.adressParking{
    font-size: 1.1rem;
    font-weight: 100;
    margin-top: 0.5rem;

}

.priceParking{
    font-size: 1.1rem;
    font-weight: 100;
    margin-top: 0.5rem;
}

/* Modal detalles de parqueadero */
.modalDetails{
    width: 75%;
    height: 90%;
    display: flex;
    /* align-items: center; */
    position: fixed;
    flex-direction: column;
    z-index: 2000;
    color: white;
    border: solid 1px #000000;
    background: linear-gradient(to right, #ffffff, #ffffff);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: showModalUser 0.5s; 
}

.contUp > h3{
    color: #000000;
    font-size: 2rem;
}

.contUp{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.contUp > h3{
    align-self: center;
    color: #04BF9D;
    margin: 1rem;
}

.contImages{
    width: 65%;
    height: 70vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* gap: 20px; */
    overflow-y: hidden;
}

.containerAside{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;

    /* align-items: center; */
}

.containerAside > hr{
    width: 1px;
    height: 80vh;
}
#imgPrin{
    width: 80%;
    height: 50%;
    margin-bottom: 1rem;

}

.conttImages{
    width: 60%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.imgModal{
    width: 30%;
    height: 50%;
}
.contInfoPark{
    width: 40%;
    height: 99%;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-evenly;
    /* border: solid 2px gray; */
}

.contInfoPark>p{
    font-size: 1.2rem;
    
}

.contInfoPark>p > span{
    color: #04BF9D;
    font-size: 1.2rem;
    margin-left: 1rem;
    font-weight: bold;
    margin-right: 10px;
}



/* DIV perfil y cerrar sesion */
.optionsUser{
    display: none;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 15rem;
    /* height: 10rem; */
    background-color: white;
    top: 11%;
    right: 0%;
    z-index: 20;
}

.linkOptions{
    display: flex;
    align-items: center;
    color: #000000;
    margin: 1rem 0 1rem 0;
    width: 100%;
    height: 50%;
    font-size: 1.2rem;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.optionsUser-active{
    display: flex;
    opacity: 0;
    animation: fade 0.5s ease-in-out forwards;
    transition: all 2s;
}


  /* Esstilos para el perfil del usuario */
.user-profile{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
}

.containerInputs{
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.containerInputsVehicle{
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.contBtns{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.containerall{
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.containerInfo{
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.personalInfo{
    width: 50%;
    height: 100%;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    /* background-color: #ffffff; */
}




.spanInfo{
    color: #04BF9D;
    font-weight: bolder;
    margin-left: 3rem;
}

.allVehicles{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contVehicles{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.allVehicles > h2{
    font-size: 2rem;
    overflow-y: hidden;
}

.swal2-icon{
    overflow-x: hidden;
}


.vehicle{
    width: 50%;
    /* height: 100%; */
    height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    /* margin: 1rem; */
}
.vehicle > img {
    width: auto;
    height: 80%;
    max-width: 100%;
    max-height: 100%;
  }
  .vehicleImagePapel {
    width: auto;
    height: 80%;
    max-width: 100%;
    max-height: 100%;
  }
.vehicle > p{
    font-size: 1.2rem;
    overflow: hidden;
}

.vehicle > p> span{
    color: #04BF9D;
    font-size: 1.2rem;
}

.addVehicle{
    width: 50%;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 7rem;
    transition: .3s linear;
    cursor: pointer;
}

.addVehicle:hover{
    color: #04BF9D;
}

.modalVehicle{
    width: 75%;
    height: 78%;
    display: flex;
    /* align-items: center; */
    position: fixed;
    flex-direction: column;
    z-index: 2000;
    color: rgb(0, 0, 0);
    border: solid 1px #000000;
    background: #d5e5f2;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: showModalUser 0.5s; 
}

.modalVehicle > h2{
    /* height: 20%; */
    padding: 2rem;
    font-size: 2.4rem;
    background-color: #04BF9D;
    text-align: center;
}

#selectVehicle{
    width: 17%;
    height: 2rem;
    font-size: 17px;
    align-self: center;
    /* position: absolute; */
    border: solid 1px #04BF9D;
    border-radius: 10px;
    color: #7b7b7b;
    right: 5%;
    bottom: 58%;
}



/* estilos pagina detalles parqueadero */

.detailParkPage{
    width: 100vw;
    height: 100vh;
}


#mainDetailPark{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contInformation{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

#nameParkingDetail{
    margin: 1rem;
}

.contImagesParking{
    width: 92%;
    height: 65%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

#imgPrinParking{
    width: 50%;
    height: 21.5rem;
    align-self: center;
    margin-bottom: 10px
}


.contImgPequeñas{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow: hidden;
}

.peqImg{
    width: 45%;
    height: 49%;
    overflow: hidden;
}

.imgModalParking{
    width: 100%;
    height: 10rem;
}
.down{
    margin-top: 10px;
}


.cardDataParking{
    width: 40%;
    /* height: auto; */
    display: flex;
    border: solid ;
    flex-direction: column;
    font-size: 1.2rem;
    justify-content: space-evenly;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    padding: 24px;
    margin: 1rem 4.5rem 3rem 0rem ;
    box-shadow: rgba(0, 0, 0, 0.335) 0px 6px 16px
}
.cardDataParking >h2{
    align-self: center;
    color: #04BF9D;
}

.contInfoParking{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.anfitrion{
    width: 50%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    font-size: 1.5rem;
    padding: 2rem;
    justify-content: space-evenly;
}

.anfitrion> h2{
    color: #04BF9D;
    margin-bottom: 2rem;
    align-self: center;

}
.anfitrion > p{
    margin-bottom: 3rem;
    margin-left: 4rem;
}

.anfitrion > p > span{
    color: #04BF9D;
    margin-bottom: 4rem;
}

.Bg-MapParking{
    align-self: center;
}


.cardDataParking > p{
    font-weight: 700;
}

.pTittle{
    color: #04BF9D;
}

.pTittle > span{
    color: #000000;
}

.priceSpan{
    font-weight: 400;
}


.horarios{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}
#pHorarios{
    color: #04BF9D;
}

.open{
    border: solid 2px rgb(174, 174, 174);
    border-radius: 10px;
    padding: 4px;
}


.btnBooking{
    align-self: center;
    background: linear-gradient(to right, #04BF9D, #f6f6f6);
    border-radius: 20px;
    border: 1px solid #000000;
    color: #000000;
}
.btnBooking:hover{
    background: linear-gradient(to right, #000000, #04BF9D);
    color: white;
}


/* Modal Para Escoger el vehiculo para reservar */
.modalBooking{
    width: 75%;
    height: 78%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    flex-direction: column;
    z-index: 2000;
    color: rgb(0, 0, 0);
    border: solid 1px #000000;
    background: #d5e5f2;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: showModalUser 0.5s; 
}
.modalBooking > h2{
    width: 100%;
    height: 20%;
    text-align: center;
    font-size: 2.4rem;
    padding: 1.5rem;
    background: linear-gradient(to right, #dfdfdf, #04BF9D);
    border-radius: 13px 13px 0px 0px;
    border-top: solid 1px black;
}

.allVehiclesBooking{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

}

.vehicleSelected{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    outline: none;
    margin: 1rem;
    border: 2px solid transparent; 
    transition: box-shadow 0.3s ease;
}

.vehicleSelected:focus{
    box-shadow: 2px 2px 4px 4px #04BF9D;
}
.vehicleSelected:after {
    content: '';
    border: 2px solid transparent;
    z-index: -1;
    transition: border-color 0.3s ease-in-out;
  }
  

.vehicleSelected > img{
    width: 80%;
    height: 90%;
}

.vehicleSelected > p{
    font-size: 1.2rem;
    overflow: hidden
}

.vehicleSelected >p > span{
    color: #04BF9D;
    font-size: 1.2rem;
}

.btnConfirmBooking{
    margin-bottom: 2rem;
}







@media screen and (max-width: 600px) {

    .headerUser{
        width: 100%;
        height: 13vh;
        background: white;
        background: linear-gradient(to right, #dfdfdf, #04BF9D, #dfdfdf);
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow-y: hidden;
    }
    #logoHomeUser{
        width: 150px;
        height: 80px;
    }
    .headerUser > h3{
        display: none;
    }
    .contIcon{
        width: 50px;
        height: 45px;
        margin-right: 0.3rem;
        overflow: hidden;
    }
    .cardParking{
        width: 45%;
        height: 18rem;
        text-align: center;
    }
    .nameParking{
        height: 20%;
        font-size: 1.01rem;
        text-align: center;
        overflow: hidden;
    }
    .adressParking{
        height: 8%;
        font-size: 0.8rem;
        overflow: hidden;
    }
    .priceParking{
        height:8%;
        font-size: 0.8rem;
        overflow: hidden;
    }

    /*estilos perfil de usuario*/
    .vehicle> img{
        width: 90%;
        height: 50%;
    }
    .allVehicles > h2{
        text-align: center;
    }
    
}